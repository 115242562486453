import React, {FC, useState} from 'react';
import {
    CircularProgress, Grid,
    Paper,
    Table,
    TableBody,
    TableContainer, TableFooter,
    TableHead, TablePagination,
    TableRow
} from "@mui/material";
import {Data} from "./types/types";
import TableRowItem from "../TableRowItem/TableRowItem";
import {headCells} from "./const/const";
import TableBodyRowItem from "../TableBodyRowItem/TableBodyRowItem";
import TablePaginationAct from '../TablePaginationActions/TablePaginationAct';
import {KeyboardArrowDown} from "@mui/icons-material";
import {statusType} from "../../shared/types/types";

/*
PassedAdmission: Date,
    Status: 'fundraising' | 'treatment' | 'report',
    Amount: number,
    Photo: string,
    Name: string,
    Age: number,
    HealthProblem: string,
    Donate: boolean,
    Country: string,
    Hospital: string,
    ReportStatus: boolean,
    TreatmentDate: Date,
    PaymentDate: Date
 */


interface MyTableProps {
    rows: any[]
    loading: boolean
}

const MyTable:FC<MyTableProps> = ({rows, loading}) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [orderBy, setOrderBy] = useState<keyof Data>('PassedAdmission');
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    //const [reload, setReload] = useState<number>(0);
    const [selectedStatus, setSelectedStatus] = useState<statusType>('All');

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleRequestSort = (property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrderBy(property);
        //setReload(reload + 1);
        setOrder(isAsc ? 'desc' : 'asc');
    };

    console.log(selectedStatus, rows)

    const filteredRows = selectedStatus === 'All' ? rows : rows.filter(row => row.Status === selectedStatus);

    const sortedRows = [...filteredRows].sort((a, b) => {
        const aValue = a[orderBy];
        const bValue = b[orderBy];

        if (aValue === null && bValue === null) {
            return 0; // Both values are null, treat them as equal
        } else if (aValue === null) {
            return order === 'asc' ? -1 : 1; // Treat null as the smallest value for ascending order
        } else if (bValue === null) {
            return order === 'asc' ? 1 : -1; // Treat null as the smallest value for ascending order
        }

        // Handle other types
        if (aValue instanceof Date && bValue instanceof Date) {
            // Date comparison for date values
            return order === 'asc' ? aValue.getTime() - bValue.getTime() : bValue.getTime() - aValue.getTime();
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
            // Numeric comparison for number values
            return order === 'asc' ? aValue - bValue : bValue - aValue;
        } else if (typeof aValue === 'string' && typeof bValue === 'string') {
            // String comparison for string values
            return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        } else {
            // Fallback to default comparison
            return 0;
        }
    });


    return (
        <>
            {loading ?
                <CircularProgress />
             :
                <>
                    <TableContainer
                        component={Paper}
                        sx={{
                            borderRadius: '8px',
                            border: '1px solid #FFFFFF',
                            width: '100%',
                            overflowX: 'auto',
                        }}
                    >
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" className="font">
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell, index) => (
                                        <TableRowItem headCell={headCell}
                                                      orderBy={orderBy}
                                                      order={order}
                                                      handleRequestSort={handleRequestSort}
                                                      key={index}
                                                      statusValue={selectedStatus}
                                                      handleStatusChange={(event) => setSelectedStatus(event.target.value as statusType)}
                                        />
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                   <TableBodyRowItem row={row} index={index} key={index}/>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                            sx={{
                                marginTop: '20px',
                            }}
                    >
                        <TableFooter>

                            <TablePagination
                                colSpan={3}
                                count={filteredRows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                rowsPerPageOptions={[5, 20, 50]}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationAct}
                                labelRowsPerPage={'Records per page: '}
                                slotProps={{
                                    select: {
                                        IconComponent: (props) => (<KeyboardArrowDown {...props} sx={{
                                            color: 'white !important',
                                            marginTop: '5px',
                                            width: '16px',
                                            height: '16px',
                                        }}/>)
                                    }
                                }}
                                sx={{
                                    borderBottom: 0,
                                    color: 'white'
                                }}
                            />

                        </TableFooter>
                    </Grid>
                </>
            }
        </>
    );
};

export default MyTable;
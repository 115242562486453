import React, {useEffect, useState} from 'react';
import './App.css';
import {logo} from "./assets/logo/logo";
import {CircularProgress, Grid} from "@mui/material";
import MyTable from "./components/MyTable/MyTable";
import {baseURL, fields} from "./components/MyTable/const/const";

function App() {
    
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState<any[]>([]);
    const [statusCounts, setStatusCounts] = useState({
        Fundraising: 0,
        Treatment: 0,
        Report: 0,
    });


    useEffect(() => {
        fetchData().then((data) => {
            setRows(data);
            setLoading(false);
            const counts = data.reduce((acc: any, item: any) => {
                ++acc[item.Status];
                return acc;
            }, { Fundraising: 0, Treatment: 0, Report: 0 });

            setStatusCounts(counts);
        });
    }, []);


    async function fetchData() {
        const data = await fetch(`${baseURL}/items/patients?limit=-1&fields=${fields}`)
        const json = await data.json()

        const result = json.data

        return result.map((i: Record<string, any>) => {
            return {
                Id: i.id, // to https://admin.helpster.charity/preview/patients/31
                PassedAdmission: i.fundraising_date ? new Date(i.fundraising_date) : null,
                Amount: i.amount,
                Photo: `${baseURL}/assets/${i.avatar}`,
                Name: `${i.first_name} ${i.last_name}`,
                Age: i.age,
                Status: i.status,
                HealthProblem: i.problem.name,
                Country: i.country,
                Hospital: i.hospital.title,
                ReportStatus: i.Report_status,
                TreatmentDate: i.treatment_date ? new Date(i.treatment_date) : null,
                PaymentDate: i.Payment_date ? new Date(i.Payment_date) : null,
                Donate: i.status === 'Fundraising',
            }
        })
    }


    const circles = <>
        <div className="circle circle1 color1"></div>
        <div className="circle circle2 color2"></div>
        <div className="circle circle3 color3"></div>
    </>

    const Header = () => (
        <p className='title header'>
            Real-time Helpster database
        </p>
    );

    const NumberBlock = () => (
        <Grid container direction="row" justifyContent="center" alignItems="center" className='numberBlock'>
            {Object.entries(statusCounts).map(([key, value]) => <span>
                <p className='title'>
                    { value > 0 ? key === 'Report' ? value + 131 : value : <CircularProgress /> }
                </p>

                <p className='description numberDesc'>
                    {key === 'Treatment'
                        ? 'Awaiting reports'
                        : key === 'Report'
                            ? 'Completed'
                            : key === 'Fundraising'
                                ? 'In fundraising'
                                : null}
                </p>
            </span>
            )}
        </Grid>
    );

    return (
        <>
            <div className="wrapper">
                <div className="container font">
                    <main className='main'>
                        {circles}
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <div className='icon'>
                                {/* { window.innerWidth > 768 ? window.innerWidth > 992 ? desctop : table : mobile } */}
                                { window.innerWidth < 768 && logo(60,45) }
                                { window.innerWidth >= 768 && window.innerWidth < 992 && logo(100,75) }
                                { window.innerWidth >= 992 && logo(160,120) }
                            </div>
                            <Header/>
                            <span className='description headerDescription'>
                                <p>This is a mirrored public version of our universal Helpster Admin Panel.</p>
                                <p>Transparency is our top priority. Please explore</p>
                            </span>
                            <NumberBlock/>
                            <MyTable rows={rows} loading={loading}/>
                        </Grid>
                    </main>
                </div>
            </div>
        </>

    );
}

export default App;

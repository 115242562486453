import React from "react";

export const logo = (width, hight) =>
{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={hight} viewBox="0 0 160 120" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 28.8379L81.0159 120L160 31.7787L131.657 0H26.7175L0 28.8379ZM14.7287 29.0271L53.7383 72.284V12.0469H29.7637L14.7287 29.0271ZM108.901 73.2327L146.031 31.7781L128.304 12.0469H108.901V73.2327ZM70.0957 12.0469H92.8513V44.0153H70.0957V12.0469ZM92.9528 59.2891H70.0957V90.4476L81.7782 103.4L92.9528 91.1537V59.2891Z"
                  fill="white"/>
        </svg>
    )
}
import {styled, TableCell, tableCellClasses, TableRow} from "@mui/material";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#6207EB',
        color: theme.palette.common.white,
        border: '1px solid #FFFFFF',
        '&:last-child td': {
            border: '1px solid red',
        },
        
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Ubuntu",
        // [theme.breakpoints.down('md')]: {
        // //   backgroundColor: theme.palette.secondary.main,
        // },
        // [theme.breakpoints.up('md')]: {
        // //   backgroundColor: theme.palette.primary.main,
        // },
        // [theme.breakpoints.up('lg')]: {
        //     // backgroundColor: theme.palette.secondary.main,
        // },
        // padding: "16px 0px 16px 20px",
        padding: window.innerWidth > 768 ? 
            window.innerWidth > 992 ? 
                "4px 0px 4px 20px" 
                : "4px 0px 4px 16px" 
                : "4px 0px 4px 16px",
        color: theme.palette.common.white,
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: '#1D162B',
    // position: 'relative',
    // '&:last-child th::after': {
    //     content: '""',
    //     position: 'absolute',
    //     top: 0,
    //     left: 0,
    //     width: '3px', // Ширина линии
    //     height: '100%', // Высота линии
    //     background: 'radial-gradient(circle at 0% -10%, #4e2a87 5%, transparent 20%), ' +
    //         'radial-gradient(circle at 400% 60%, #3b5998 5%, transparent 20%)',
    //     backgroundColor: '#d7cdea',
    //     zIndex: 1,
    // },
    '& th, & td':{
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&': {
        border: '1px solid #1D162B',
    }
}));

export const StyledTablePagination = styled(TableRow)(({ theme }) => ({
    color: 'white',
}));
import React, {FC} from 'react';
import {StyledTableCell, StyledTableRow} from "../MyTable/styles/styles";
import {addLeadingZero} from "../MyTable/lib/lib";


interface TableBodyRowItemProps {
    row: any;
    index: number;
}


const TableBodyRowItem:FC<TableBodyRowItemProps> = ({row, index}) => {
    return (
        <StyledTableRow key={`${row.PassedAdmission}-${index}`}>
            <StyledTableCell component="th" scope="row">
                {row.PassedAdmission ? `${addLeadingZero(row.PassedAdmission.getUTCDate())}-${addLeadingZero(
                    row.PassedAdmission.getMonth() + 1
                )}-${addLeadingZero(row.PassedAdmission.getYear()-100)}` : '-'}
            </StyledTableCell>
            <StyledTableCell>
                <span
                    style={{
                        borderRadius: '8px',
                        padding: '4px 8px',
                        color: 'white',
                        fontWeight: 500,
                        fontSize: '12px',
                        textTransform: 'capitalize',
                        background:
                            row.Status === 'Treatment'
                                ? '#FFCA00'
                                : row.Status === 'Report'
                                    ? '#6207EB'
                                    : row.Status === 'Fundraising'
                                        ? '#16D9A6'
                                        : 'transparent',
                    }}
                >
                    {row.Status}
                </span>
            </StyledTableCell>
            <StyledTableCell>
                <div
                    style={{
                        width: window.innerWidth > 768 ? window.innerWidth > 992 ? "40px" : "32px" : '30px',
                        height: window.innerWidth > 768 ? window.innerWidth > 992 ? "40px" : "32px" : '30px',
                        borderRadius: '50%',
                        overflow: 'hidden',
                    }}
                >
                    <img
                        crossOrigin={'anonymous'}
                        src={row.Photo}
                        alt="Profile"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </div>
            </StyledTableCell>
            <StyledTableCell>
                      <a
                          href={`https://share.helpstercharity.org/${row.Id}`}
                          target="_blank"
                          style={{
                              textDecoration: 'underline',
                              color: 'white',
                              fontSize: '14px',
                          }}
                      >
                        {row.Name}
                      </a>
            </StyledTableCell>
            <StyledTableCell>${row.Amount}</StyledTableCell>
            <StyledTableCell>{row.Age}</StyledTableCell>
            <StyledTableCell>{row.HealthProblem}</StyledTableCell>
            <StyledTableCell>
                {row.Donate ? (
                    <a
                        href={'https://helpstercharity.org/help'}
                        target="_blank"
                        style={{
                            borderRadius: '30px',
                            padding: '8px 28px',
                            background: 'var(--System-Second, #CC01D0)',
                            fontWeight: 500,
                            color: 'white',
                            fontSize: '16px',
                            cursor: 'pointer',
                            textDecoration: 'none'
                        }}
                    >
                        Donate
                    </a>
                ) : (
                    'Closed'
                )}
            </StyledTableCell>
            <StyledTableCell>{row.Country}</StyledTableCell>
            <StyledTableCell>{row.Hospital}</StyledTableCell>
            <StyledTableCell>
                {row.ReportStatus ? 'Report approved' : '-'}
            </StyledTableCell>
            <StyledTableCell>
                {row.TreatmentDate ? `${addLeadingZero(row.TreatmentDate.getUTCDate())}-${addLeadingZero(
                    row.TreatmentDate.getMonth() + 1
                )}-${addLeadingZero(row.TreatmentDate.getFullYear())}` : '-'}
            </StyledTableCell>
            <StyledTableCell>
                {row.PaymentDate ? `${addLeadingZero(row.PaymentDate.getUTCDate())}-${addLeadingZero(
                    row.PaymentDate.getMonth() + 1
                )}-${addLeadingZero(row.PaymentDate.getFullYear())}` : '-'}
            </StyledTableCell>
        </StyledTableRow>
    );
};

export default TableBodyRowItem;
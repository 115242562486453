import React, {FC} from 'react';
import sortIcon from "../../assets/logo/sortIcon";
import {Data, HeadCell} from "../MyTable/types/types";
import {StyledTableCell} from "../MyTable/styles/styles";
import {Grid, Select, SelectChangeEvent} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import {logo} from "../../assets/logo/logo";
import {statusType} from "../../shared/types/types";

interface TableRowItemProps {
    headCell: HeadCell;
    orderBy: keyof Data;
    order: 'asc' | 'desc';
    handleRequestSort: (property: keyof Data) => void;
    statusValue: statusType;
    handleStatusChange: (event: SelectChangeEvent<statusType>) => void;
}

const TableRowItem:FC<TableRowItemProps> = ({
                                                headCell,
                                                order,
                                                handleRequestSort,
                                                orderBy,
                                                statusValue,
                                                handleStatusChange
                                            }) => {
    console.log(statusValue)

    return (
        <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
        >
                <span>
                    <Grid
                        container
                        direction="row"
                        justifyContent={`${headCell.id === 'Donate' || 'PassedAdmission' ? 'center' : 'flex-start'}`}
                        alignItems="center"
                        gap='10px'
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        <span>{headCell.label}</span>
                        <span onClick={() => handleRequestSort(headCell.id)}>
                            {headCell.id === 'PassedAdmission' && sortIcon(orderBy, headCell, order)}
                        </span>

                        {headCell.id === 'Status' &&
                            <Select
                                value={statusValue}
                                onChange={handleStatusChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                // MenuProps={{
                                //     sx:{
                                //     color: 'white',
                                // }
                                // }}
                                //IconComponent={<KeyboardArrowDown/> }
                                sx={{
                                    width: '124px',
                                    height: '32px',
                                    background: '#8945F0',
                                    color: 'white',
                                    borderRadius: '8px',
                                    fontSize: '14px',
                                }}
                            >
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="Treatment">Treatment</MenuItem>
                                <MenuItem value="Fundraising">Fundraising</MenuItem>
                                <MenuItem value="Report">Report</MenuItem>
                            </Select>
                        }

                        {headCell.id === 'Donate' && logo(20, 20)}
                    </Grid>
                </span>

        </StyledTableCell>
    );
};

export default TableRowItem;
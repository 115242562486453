import React from 'react';
import {Data, HeadCell} from "../../components/MyTable/types/types";

const SortIcon = (sortColumn: keyof Data, headCell: HeadCell, order: 'asc' | 'desc') => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            style={{
                cursor: 'pointer',
                transform: sortColumn === headCell.id && order === 'desc' ? 'scaleY(-1)' : 'none',
            }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0001 12.75H2.00006V11.25H14.0001V12.75ZM2.00006 8.75H10.0001V7.25H2.00006V8.75ZM2.00006 4.75H6.00006V3.25H2.00006V4.75Z"
                fill="white"
            />
        </svg>
    );
};

export default SortIcon;
import {HeadCell} from "../types/types";
import photo from '../../../assets/img/photo.png'

export function createData(
    PassedAdmission: Date,
    Status: 'fundraising' | 'treatment' | 'report',
    Amount: number,
    Photo: string,
    Name: string,
    Age: number,
    HealthProblem: string,
    Donate: boolean,
    Country: string,
    Hospital: string,
    ReportStatus: boolean,
    TreatmentDate: Date,
    PaymentDate: Date
) {
    return {
        PassedAdmission,
        Status,
        Amount,
        Photo,
        Name,
        Age,
        HealthProblem,
        Donate,
        Country,
        Hospital,
        ReportStatus,
        TreatmentDate,
        PaymentDate,
    };
}

export const headCells: readonly HeadCell[] = [
    {
        id: 'PassedAdmission',
        label: 'Admitted',
    },
    {
        id: 'Status',
        label: 'Status',
    },
    {
        id: 'Photo',
        label: 'Photo',
    },
    {
        id: 'Name',
        label: 'Name',
    },
    {
        id: 'Amount',
        label: 'Amount',
    },
    {
        id: 'Age',
        label: 'Age',
    },
    {
        id: 'HealthProblem',
        label: 'Health Problem',
    },
    {
        id: 'Donate',
        label: 'Donate',
    },
    {
        id: 'Country',
        label: 'Country',
    },
    {
        id: 'Hospital',
        label: 'Hospital',
    },
    {
        id: 'ReportStatus',
        label: 'Report status',
    },
    {
        id: 'TreatmentDate',
        label: 'Treatment date',
    },
    {
        id: 'PaymentDate',
        label: 'Payment date',
    },
];

export const baseURL = 'https://admin.helpster.charity'

export const fields = [
    '*',
    'problem.name',
    'hospital.title',
].join(',')